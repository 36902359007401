import React from 'react';

import _ from 'lodash';

import PersonCard from './Card';
import TEAM_ORDER from './order';

const TeamList = ({ persons }) => {
  const byPosition = _.groupBy(persons, ({ node: team }) => team.frontmatter.position);

  return (
    <div className="team__wrapper">
      { Object.keys(TEAM_ORDER).map(position => (
        <div className="team__list__wrapper" key={ position } id={ position }>
          <h3 className="page__subtitle">{ position }</h3>
          <div className="team__list">
            { !!byPosition[position] && byPosition[position].map(({ node: team }) => (
              <PersonCard
                key={ team.id }
                person={ team }
              />
            )) }
          </div>
        </div>
      )) }
    </div>
  );
};

export default TeamList;
