import React from 'react';

import Img from 'gatsby-image';
import { Link } from 'gatsby';

import TEAM_ORDER from './order';

const PersonCard = ({ person }) => {
  const featuredImg = person.frontmatter.image.childImageSharp.fixed;
  // eslint-disable-next-line max-len
  const positionSingular = Object.prototype.hasOwnProperty.call(TEAM_ORDER, person.frontmatter.position)
    ? TEAM_ORDER[person.frontmatter.position]
    : person.frontmatter.position;

  return (
    <div className="team__card">

      <div className="team__card__content">

        { person.frontmatter.showReadMore
          ? (
            <Link to={ person.fields.slug } className="team__card__img__link">
              <Img className="team__card__img" fixed={ featuredImg } />
            </Link>
          )
          : (
            <span className="team__card__img__link">
              <Img className="team__card__img" fixed={ featuredImg } />
            </span>
          ) }

        <h3 className="team__card__position">
          { positionSingular }
        </h3>

        <h2 className="team__card__name">

          { person.frontmatter.showReadMore
            ? (
              <Link to={ person.fields.slug } className="team__card__name__link">
                { person.frontmatter.name }
              </Link>
            )
            : person.frontmatter.name }
        </h2>

        <a className="team__card__email" href={ `mailto:${person.frontmatter.email}` }>
          { person.frontmatter.email }
        </a>

        { person.frontmatter.showReadMore && (
          <Link to={ person.fields.slug } className="team__card__link">
            Read More
          </Link>
        ) }
      </div>

    </div>
  );
};

export default PersonCard;
