import React, { useEffect, useState } from 'react';

const TeamPositions = ({ positions, scrollRef }) => {
  const [ section, setSection ] = useState('');
  useEffect(() => {
    if (section) {
      document.getElementById(section).scrollIntoView({
        behavior: 'smooth',
      });
      setSection('');
    }
  }, [ section ]);

  return (
    <div className="publications__filter__wrapper--team" ref={ scrollRef }>
      Select a position:
      <ul className="publications__filter__list">
        { positions.map(position => (
          <li className="publications__filter" key={ position }>
            <a
              className="publications__filter__link"
              href={ `#${position}` }
              onClick={ e => { e.preventDefault(); setSection(position); } }
            >
              { position }
            </a>
          </li>
        )) }
      </ul>
    </div>
  );
};


export default TeamPositions;
