/* eslint-disable quote-props */
const TEAM_ORDER = {
  'Group Leaders': 'Group Leader',
  'Post Docs': 'Post Doc',
  'PhD Students': 'PhD Student',
  'Pre-doctoral Students': 'Pre-doctoral Student',
  'Staff': 'Staff',
};

export default TEAM_ORDER;
