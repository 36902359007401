import React, { useEffect, useRef } from 'react';


const ScrollUp = ({ scrollRef }) => {
  const buttonRef = useRef(null);

  // When user scrolls down from top of the document to bottom of filter, show button
  useEffect(() => {
    const scroll = () => {
      if (buttonRef.current && scrollRef) {
        if (window.pageYOffset > scrollRef.current.getBoundingClientRect().bottom) {
          buttonRef.current.style.display = 'block';
        }
        else {
          buttonRef.current.style.display = 'none';
        }
      }
    };
    window.addEventListener('scroll', scroll);
    return () => window.removeEventListener('scroll', scroll);
  }, [ scrollRef ]);

  // When the user clicks on the button, scroll to the top of the document
  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    /* eslint-disable-next-line jsx-a11y/control-has-associated-label */
    <button
      ref={ buttonRef }
      type="button"
      className="scroll-up-button"
      id="scroll-up-btn"
      onClick={ scrollUp }
    />
  );
};

export default ScrollUp;
